.headerRow {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 3vh 0px;
  padding-bottom: 0px;
}

.drawerIcon {
  position: absolute;
  right: 10px;
  height: 10vw;
  cursor: pointer;
}

.messageBadgeCntr {
  position: absolute;
  top: 0px;
  left: -0.5vw;
  background-color: red;
  color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 5vw;
  font-size: 0.6rem;
}

.dashboardLogo {
  height: 9vh;
}

.buttonIcon {
  position: absolute;
  left: 0px;
  height: 13vh;
}

.centerIcon {
  object-fit: contain;
  flex: 1;
  width: 5vh;
}

.gpicTextTop {
  text-align: center;
  direction: rtl;
  white-space: nowrap;
  color: white;
  font-weight: bold;
  font-size: 1.7em;
}

.gpicTextBottom {
  text-align: center;
  direction: rtl;
  color: white;
  white-space: nowrap;
  margin-right: 10%;
  margin-top: 1vh;
  font-size: 0.9em;
}

.firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1vh 1vh;
}

.userDetailContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding: 1vh;
}

.userProfile {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.profilePic {
  background-color: white;
  border-radius: 50%;
  height: 15vw;
  width: 15vw;
  padding: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectProfilePic {
  color: var(--green);
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vh;
  height: 2.5vh;
  font-size: 12px;
  margin-top: -10px;
}

.usernameText {
  color: white;
}

.userAmountsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.amountContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.amountText {
  position: absolute;
  direction: rtl;
  color: black;
  font-size: 0.8rem;
}

.amountBG {
  height: 5vw;
  width: 30vw;
  background-color: white;
  border-radius: 3vw;
}

.userDetailIcon {
  width: 4.9vw;
  height: 4.9vw;
  margin-left: -4vw;
  background-color: white;
  padding: 0.1vw;
  border-radius: 50%;
  object-fit: contain;
}

.firstRowLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.3;
  width: 100%;
  height: 100%;
}

.firstRowInnerRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.messages {
  position: relative;
  justify-content: center !important;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 15px;
}

.moneyWallet {
  justify-content: center !important;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 15px;
}

.leaderBoard {
  justify-content: center !important;
  padding: 10px;
  border-radius: 15px;
}

.inviteFriends {
  position: relative;
  padding: 5px;
  flex-direction: column;
  border-radius: 15px;
}

.inviteText {
  white-space: nowrap;
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 0.5rem;
  height: 100%;
}

.inviteIcon {
  position: absolute;
  left: 0px;
  bottom: 0px;

  object-fit: contain;
  flex: 1;
  width: 5vh;
}

.secondRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1vh 1vh;
}

.dailyChallengeTextTop {
  direction: rtl;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.2em;
  white-space: nowrap;
}

.dailyChallengeTextBottom {
  direction: rtl;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
  font-size: 0.9em;
}

@media (max-width: 360px) {
  .dailyChallengeTextBottom {
    font-size: 0.6em;
  }
}

.exerciseTextContainer {
  height: 60%;
  width: 35vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 2vw;
}

.exerciseText {
  direction: rtl;
  color: white;
  font-weight: bold;
}

.exerciseTextBottom {
  direction: rtl;
  color: #4a0303;
  width: 100%;
  white-space: nowrap;
}

.thirdRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1vh 1vh;
}

.startTextTop {
  text-align: center;
  direction: rtl;
  white-space: nowrap;
  color: white;
  font-weight: bold;
  font-size: 1.7em;
  /* margin-top: -20%; */
  margin-right: 50%;
}

.startTextBottom {
  text-align: center;
  direction: rtl;
  color: white;
  white-space: nowrap;
  margin-right: 50%;
  margin-top: -1vh;
  font-size: 1em;
}

.forthRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1vh 1vh;
}

.haveRollCntr {
  position: absolute;
  top: 1vw;
  left: 1vw;
  background-color: red;
  color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vh;
  height: 4vh;
  font-size: 1.2em;
  border: 1px solid black;

  animation-duration: 1s;
  animation-name: changeHaveRollCntrSize;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes changeHaveRollCntrSize {
  from {
    width: 4vh;
    height: 4vh;
    font-size: 1.2em;
  }

  to {
    width: 6vh;
    height: 6vh;
    font-size: 1.4em;
  }
}

.luckySpinText {
  direction: rtl;
  color: white;
  font-weight: bold;
}

.shopText {
  direction: rtl;
  color: white;
  font-weight: bold;
}

.fifthRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1vh 1vh;
}

.chestTextTop {
  position: absolute;
  direction: rtl;
  color: gold;
  background-color: rgb(37, 37, 37);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 1vh 3vh;
  white-space: nowrap;
  font-size: 0.7em !important;
}

.chestTextBottom {
  direction: rtl;
  color: black;
  white-space: nowrap;
  font-size: 0.7em !important;
}

.tapCoinText {
  direction: rtl;
  color: black;
  font-weight: bold;
  text-align: center;
}

.specialPlanText {
  direction: rtl;
  color: white;
  font-weight: bold;
  text-align: center;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}
