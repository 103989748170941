.container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  flex: 1;
  width: 100%;
  height: 13vh;
  background-color: white;
  padding-right: 5px;
  font-size: 1em;
  text-decoration: none;
  overflow: hidden;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    font-size: 2em;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    font-size: 1em;
  }
}
