.selectAvatarBody {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 70vh;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.selectAvatarBody .buyButton {
  position: absolute;
  cursor: pointer;
  padding: 5px;
  bottom: -8vw;
  width: 40vw;
  height: 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: white;
}

.selectAvatarBody .buyButton .text {
  font-size: 14px;
  font-weight: bold;
  color: var(--green);
  white-space: nowrap;
}

.selectAvatarBody .header {
  padding: 5px;
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.selectAvatarBody .header .left {
  display: flex;
  align-items: center;
  background-color: black;
  border-radius: 25px;
  padding: 2px;
}

.selectAvatarBody .header .left .option {
  text-align: center;
  color: white;
  width: 40px;
  height: 100%;
  font-size: 12px;
  border-right: 1px solid white;
  cursor: pointer;
}

.selectAvatarBody .header .right {
  display: flex;
  align-items: center;
}

.selectAvatarBody .header .right .title {
  color: gray;
  font-size: 18px;
}

.selectAvatarBody .header .profileIcon {
  width: 10vw;
}

.selectAvatarBody .avatars {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.selectAvatarBody .avatars .avatar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 14vh;
  width: 12vh;
  background-color: gold;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

.selectAvatarBody .avatars .avatar .pic {
  width: 95%;
}

.selectAvatarBody .avatars .avatar .tickIcon {
  position: absolute;
  width: 5vw;
  top: 0px;
  right: 0px;
}

.selectAvatarBody .avatars .avatar .lockIcon {
  position: absolute;
  width: 5vw;
  top: 0px;
  left: 0px;
}

.selectAvatarBody .avatars .avatar .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: gray;
  border-radius: 5px;
  height: 2vh;
  width: 50%;
  white-space: nowrap;
  font-size: 1.4vh;
}

.tag .coinIcon {
  height: 100%;
  margin-left: -14px;
}

.tag .text {
  margin-right: 4vw;
}

.formBody {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 40vh;
  background-color: rgb(1, 119, 92);
  border-radius: 10px;
}

.profileContainer {
  position: absolute;
  top: -6vh;
  right: 0px;
}

.profilePic {
  background-color: white;
  border-radius: 50%;
  height: 12vh;
  width: 12vh;
  padding: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectProfilePic {
  color: black;
  background-color: gold;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12vh;
  height: 3vh;
  font-size: 0.8em;
  margin-top: -10px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35vh;
}

.input {
  border: 1px solid gray;
  color: #038e6e;
  border-radius: 1vh;
  margin-bottom: 1vh;
  width: 100%;
  padding: 2vh;
  font-size: 2vh;
  direction: rtl;
}
