.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3vw;
}

.infoTag {
  background-color: white;
  border-radius: 50px;
  padding: 1vw;
  display: flex;
  min-width: fit-content;
  width: 20vw;
  margin-right: 2vw;
}

.infoNumber {
  border-right: 1px solid gray;
  font-weight: bold;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
}

.speakerIcon {
  width: 5vw;
  height: 5vw;
  margin: 0px 2vw;
}

.gpicIcon {
  width: 7vw;
  height: 5vw;
  margin-left: 1vw;
  margin-top: 0.5vw;
}

.starIcon {
  width: 5vw;
  height: 5vw;
  margin-top: 1vw;
  margin-left: 2vw;
}

.listContainer {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-wrap: wrap;
  padding-left: 7vw;
  margin-top: 5vw;
}

.gameStageContainer {
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14vh;
  height: 14vh;
  margin-right: 3vh;
  margin-bottom: 10vh;
  border-radius: 10px;
}

.gameStageText {
  color: var(--green);
  font-size: 2em;
  font-weight: bold;
}

.gameStageIcons {
  position: absolute;
  bottom: -6.5vh;
}

.gameStageIcon {
  width: 4vh;
}

.gameTopSection {
  background-color: #6d6e70;
  padding: 4vh;
  padding-top: 0px;
  border-radius: 30px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f383c;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.timer {
  background-color: gold;
  color: var(--green);
  width: 35vw;
  height: 6vh;
  margin: 2vw;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.4em;
}

.timerIcon {
  position: absolute;
  left: 0px;
  width: 5vh;
  height: 5vh;
}

.gameImage {
  height: 25vh !important;
  border-radius: 30px;
  background: url("../../resources/img/loading_page.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vw;
  object-fit: contain;
  filter: blur(20px);
  -webkit-filter: blur(20px); /* Safari 6.0 - 9.0 */
}

.selectedAnsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: 2vw;
  direction: rtl;
}

.gameKeyboardSection {
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
  margin: 3vw;
  justify-content: center;
  align-items: center;
}

.gameHelpSection {
  background-color: #1f383c;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  height: 10vh;
  padding: 1vw;
}

.helpIcon {
  padding: 1vh;
}

.helpPrice {
  position: absolute;
  bottom: 0px;
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 0.6vw;
  font-size: 14px;
}

.letterBox {
  background-color: white;
  width: 6vh;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  margin-right: 1vh;
  margin-top: 1vh;
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .gameStageText {
    font-size: 4em;
  }

  .infoNumber {
    font-size: 2em;
  }
}
