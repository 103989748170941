@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wheelImage {
  width: 80%;
}

.indicatorImage {
  position: absolute;
  top: 8vh;
  z-index: 1;
}

.wheelMotion {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.spinIconContainer {
  position: relative;
  cursor: pointer;
}

.spinIconCounter {
  position: absolute;
  right: 40%;
  background-color: yellow;
  padding: 1vw;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  font-size: 1.2em;
}

.spinIcon {
  width: 25vw;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .spinIconCounter {
    padding: 2vw;
    font-size: 1.6em;
  }
}
