@font-face {
  font-family: Vazir;
  src: url("./resources/font/Vazir-FD.eot");
  src: url("./resources/font/Vazir-FD.eot?#iefix") format("embedded-opentype"),
    url("./resources/font/Vazir-FD.woff2") format("woff2"),
    url("./resources/font/Vazir-FD.woff") format("woff"),
    url("./resources/font/Vazir-FD.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("./resources/font/Vazir-Bold-FD.eot");
  src: url("./resources/font/Vazir-Bold-FD.eot?#iefix")
      format("embedded-opentype"),
    url("./resources/font/Vazir-Bold-FD.woff2") format("woff2"),
    url("./resources/font/Vazir-Bold-FD.woff") format("woff"),
    url("./resources/font/Vazir-Bold-FD.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("./resources/font/Vazir-Black-FD.eot");
  src: url("./resources/font/Vazir-Black-FD.eot?#iefix")
      format("embedded-opentype"),
    url("./resources/font/Vazir-Black-FD.woff2") format("woff2"),
    url("./resources/font/Vazir-Black-FD.woff") format("woff"),
    url("./resources/font/Vazir-Black-FD.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("./resources/font/Vazir-Medium-FD.eot");
  src: url("./resources/font/Vazir-Medium-FD.eot?#iefix")
      format("embedded-opentype"),
    url("./resources/font/Vazir-Medium-FD.woff2") format("woff2"),
    url("./resources/font/Vazir-Medium-FD.woff") format("woff"),
    url("./resources/font/Vazir-Medium-FD.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("./resources/font/Vazir-Light-FD.eot");
  src: url("./resources/font/Vazir-Light-FD.eot?#iefix")
      format("embedded-opentype"),
    url("./resources/font/Vazir-Light-FD.woff2") format("woff2"),
    url("./resources/font/Vazir-Light-FD.woff") format("woff"),
    url("./resources/font/Vazir-Light-FD.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("./resources/font/Vazir-Thin-FD.eot");
  src: url("./resources/font/Vazir-Thin-FD.eot?#iefix")
      format("embedded-opentype"),
    url("./resources/font/Vazir-Thin-FD.woff2") format("woff2"),
    url("./resources/font/Vazir-Thin-FD.woff") format("woff"),
    url("./resources/font/Vazir-Thin-FD.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

* {
  font-family: "Vazir", sans-serif;
  box-sizing: border-box;
  --green: #038e6e;
}

:focus {
  outline: none;
}

body,
html {
  position: fixed;
}


/* 
! possible fix  
html, body, #root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modalOutside {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;

  background-color: rgba(128, 128, 128, 0);
  transition: background-color 0.3s;
}

.modalOutside.show {
  background-color: rgba(80, 80, 80, 0.7);
}

.modalForm {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: fit-content;
  /* min-height: 500px; */
  /* min-width: 700px; */
  /* width: 50%; */
  max-height: 80%;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */

  transform: scale(0);
  transition: transform 0.2s;
}

.modalForm.show {
  transform: scale(1);
}
