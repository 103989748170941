.title {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  direction: rtl;
  width: 90%;
  margin-top: 2vw;
}

.questionInput {
  direction: rtl;
  width: 90%;
  border-radius: 20px;
  margin-top: 2vw;
  padding: 3vw;
}

.optionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 2vw;
}

.submitButton {
  background-color: #1c3a3c;
  color: white;
  border-radius: 25px;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vw 0px;
  font-weight: bold;
  margin-top: 4vw;
  cursor: pointer;
}

.questionsListButton {
  position: relative;
  background-color: white;
  border-radius: 50px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vw 0px;
  font-weight: bold;
  margin-top: 4vw;
  cursor: pointer;
}

.option {
  direction: rtl;
  border: 1vw solid red;
  width: 48%;
  padding: 3vw;
  border-radius: 35px;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}
