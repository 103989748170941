.noTransactionText {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.footer {
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2vw;
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.questionContainer {
  width: 90%;
  background-color: white;
  border-radius: 10px;
  padding: 3vw;
  margin-top: 4vw;
  display: flex;
  min-height: 40vw;
}

.columnFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.rowFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text {
  direction: rtl;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  max-width: 100%;
}

.ansContainer {
  background-color: #48d590;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  direction: rtl;
  height: 8vw;
  width: 75%;
}

.infoTagContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.infoTag {
  background-color: #1c3a3c;
  border-radius: 10px;
  padding: 1vw;
  display: flex;
  min-width: fit-content;
  width: 25vw;
}

.infoTagTitle {
  font-weight: bold;
  font-size: 0.7em;
  direction: rtl;
}

.infoNumber {
  border-right: 1px solid gray;
  font-weight: bold;
  width: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  color: white;
}

.footerIcon {
  width: 4vw;
  height: 4vw;
  margin-left: 4vw;
  margin-top: 1.5vw;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .noTransactionText {
    font-size: 2em;
  }
}
