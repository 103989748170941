.ranksContainer {
  width: 95%;
  overflow: auto;
  padding-bottom: 5px;
}

.userRankContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2vw;
}

.userLevelRight {
  display: flex;
  flex-direction: row;
}

.userRankProfilePicture {
  width: 15vw;
  height: 15vw;
}

.userLevelContainer {
  position: relative;
  background-color: #fcde7c;
  color: #044259;
  font-weight: bold;
  font-size: 1.4em;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  margin-right: 2vw;
}

.userLevelTop {
  position: absolute;
  top: -6px;
  background-color: #333333;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: lighter;
  font-size: 0.6em;
  width: 100%;
}

.typeRow {
  position: relative;
  background-color: white;
  border-radius: 10px;
  width: 70%;
  height: 10vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4vw;
  padding: 2vw;
  cursor: pointer;
}

.typeRow .icon {
  position: absolute;
  left: -4vh;
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
}

.typeRow .backImg {
  width: 8vh;
}

.typeRow .text {
  color: black;
  font-weight: bold;
  margin-right: 6vw;
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}
