.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  width: 13vh;
  height: 7vh;
  border-radius: 1vh;
  cursor: pointer;
  border-color: transparent;
}

.text {
  color: white;
  font-size: 3vh;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 9vw;
}
