.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border: 0.6vw solid #1c3a3c;
  background-color: white;
  border-radius: 25px;
  width: 90%;
  min-height: fit-content;
  margin: 2vw 0px;
}

.container .option {
  padding: 1vw;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  cursor: pointer;
}
