.drawerConatainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #e5e4e9;
  border-radius: 20px;
  width: 80vw;
  height: 90vh;
  padding: 20px 0px 15px 0px;
}

.drawerRow {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.drawerText {
  text-align: center;
  font-weight: bold;
  color: gray;
  padding: 0px 20px;
  text-decoration: none;
}

.socialMediaIcon {
  width: 16vw;
}

.drawerButton {
  border-radius: 10vh;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47%;
  height: 7vh;
  white-space: nowrap;
}

.drawerButtonIcon {
  width: 10vw;
}

.drawerShareButton {
  position: relative;
  background-color: #54487a;
  border-radius: 10vh;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 10vh;
  white-space: nowrap;
  margin-bottom: 4vh;
}

.drawerShareButtonPrize {
  margin-bottom: -6vh;
  margin-top: 1vh;
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawerShareButtonPrizeIcon {
  width: 10px;
  margin-left: 5px;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .drawerConatainer {
    width: 75vw;
    height: 80vh;
    padding: 40px 0px 35px 0px;
    font-size: 1.5em;
  }

  .drawerButtonIcon {
    width: 7vw;
  }
}
