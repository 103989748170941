.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.detailsContainer {
  width: 100%;
  background-color: #28b664;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
  padding: 5px;
}

.profilePicture {
  height: 13vw;
}

.namePrizeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 7vh;
}

.nameText {
  color: white;
  direction: rtl;
}

.prizeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.prizeText {
  color: #ead146;
  direction: rtl;
  font-weight: lighter;
}

.giftIcon {
  width: 15px;
  margin-left: 2px;
}

.rankNumberContainer {
  background-color: #fddd7f;
  border-radius: 50%;
  width: 17vw;
  height: 15vw;
  color: #044259;
  font-weight: bold;
  font-size: 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5vw solid gray;
  margin-left: 2vw;
}

.levelCountTagContainer {
  position: relative;
  background-color: #aaaaaa;
  color: white;
  border-radius: 10px;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}

.levelCountTagTop {
  position: absolute;
  top: -6px;
  background-color: #333333;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: lighter;
  font-size: 0.6em;
  width: 100%;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    font-size: 2em;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    /* font-size: 1em; */
  }
}
