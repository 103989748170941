.noTransactionText {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.qaContainer {
  border-bottom: 1px solid white;
  padding: 5px;
  margin-top: 10px;
  width: 90%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
}

.qContainer {
  border-radius: 25px;
  padding: 10px;
  direction: rtl;
  background-color: white;
  color: black;
  width: 70%;
  min-height: fit-content;
  align-self: flex-end;
}

.aContainer {
  border-radius: 25px;
  padding: 10px;
  direction: rtl;
  background-color: #009C74;
  color: white;
  margin-top: 10px;
  width: 70%;
  min-height: fit-content;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .noTransactionText {
    font-size: 2em;
  }
}
