.startConatainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  width: 90vw;
  padding: 4vw;
}

.startIcon {
  width: 10vw;
}

.startCompetitorsText {
  direction: rtl;
  width: 20vw;
  position: absolute;
  top: -7vw;
  /* font-weight: bold; */
  font-size: 1.1em;
  /* left: -1vw; */
  white-space: nowrap;
  color: white;
}

.header {
  background-color: var(--green);
  color: white;
  padding: 15px;
  width: 100%;
  height: 13vw;
  font-weight: bold;
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
  direction: rtl;
}

.startText {
  flex: 1;
  width: 100%;
  padding: 5vw;
  direction: rtl;
  font-weight: bold;
  overflow: auto;
  margin-bottom: 2vw;
  white-space: pre-wrap;
}

.startButtonsContainer {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5vw;
}

.startButtonText {
  color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25vw;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 15px;
  background-color: white;
  border: 2px solid var(--green);
  cursor: pointer;
  text-decoration: none;
}

.startButtonLeaderboard {
  background-color: #5f6734;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12vw;
  width: 17vw;
  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 25px -2px white;
  -moz-box-shadow: 0px 0px 25px -2px white;
  box-shadow: 0px 0px 25px -2px white;
}

.startLeaderboardIcon {
  width: 8vw;
}

.answer {
  background-color: #333333;
  color: white;
  border-radius: 5px;
  padding: 2vw 6vw;
  font-weight: bold;
}

.gameImage {
  height: 30vh;
  border-radius: 20px;
}

.resTitle {
  font-weight: bold;
  font-size: 1.7em;
  margin-top: 2vw;
}

.resText {
  font-weight: bold;
  font-size: 1em;
  margin-top: 2vw;
}

.gameStageIcons {
}

.gameStageIcon {
  width: 10vw;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .startConatainer {
    font-size: 1.5em;
  }
}
