.listContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
  margin-bottom: 10px;
}

.noTransactionText {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.messageContainer {
  border-bottom: 1px solid white;
  padding: 5px;
  margin-top: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.textContainer {
  border-radius: 25px;
  padding: 10px;
  direction: rtl;
  background-color: #009c74;
  color: white;
  margin-top: 10px;
  max-width: 70%;
}

.dateContainer {
  color: gray;
}

.inputContainer {
  display: flex;
  border: 1px solid gray;
  border-radius: 25px;
  padding-left: 9vw;
  width: 90%;
  margin-bottom: 10px;
}

.input {
  color: rgb(131, 120, 120);
  width: 100%;
  direction: rtl;
  padding: 5px;
  border: none;
}

.sendButton {
  width: 7vh;
  height: 7vh;
  margin-bottom: 0.5vh;
  cursor: pointer;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .noTransactionText {
    font-size: 2em;
  }
}
