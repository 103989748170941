.startConatainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  width: 70vw;
  max-height: 80vh;
  padding: 4vw 1vw;
}

.startIcon {
  width: 20vw;
  position: absolute;
  top: -4vw;
  right: -2vw;
}

.startCompetitorsText {
  direction: rtl;
  width: 20vw;
  position: absolute;
  top: -7vw;
  /* font-weight: bold; */
  font-size: 1.1em;
  /* left: -1vw; */
  white-space: nowrap;
  color: white;
}

.startTitle {
  background-color: #5f6833;
  color: white;
  padding: 15px;
  width: 100%;
  height: 13vw;
  font-weight: bold;
  font-size: 1.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
}

.startText {
  flex: 1;
  width: 100%;
  padding-bottom: 15vw;
  color: #201f1f;
  direction: rtl;
  text-align: center;
  font-weight: bold;
  overflow: auto;
  font-size: 0.8em;
  white-space: pre-wrap;
}

.priceText {
  flex: 1;
  width: 100%;
  padding: 3vw;
  margin-bottom: 5vw;
  direction: rtl;
  font-weight: bold;
  overflow: auto;
  font-size: 0.7em;
  white-space: pre-wrap;
  text-align: center;
}

.startButtonsContainer {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -6vw;
}

.startButtonText {
  color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12vw;
  width: 100%;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 10px;
  background-color: white;
  text-decoration: none;

  -webkit-box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
  -moz-box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
  box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
}

.startButtonLeaderboard {
  background-color: #5f6734;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12vw;
  width: 17vw;
  border-radius: 10px;
  margin-right: 4vw;
  text-decoration: none;

  -webkit-box-shadow: 0px 0px 25px -2px white;
  -moz-box-shadow: 0px 0px 25px -2px white;
  box-shadow: 0px 0px 25px -2px white;
}

.startLeaderboardIcon {
  width: 8vw;
}

.prizesTitle {
  width: 90%;
  direction: rtl;
  font-weight: bold;
  padding: 2vw 0px;
}

.prizesContainer {
  display: flex;
  width: 90%;
  align-items: center;
  flex-direction: row-reverse;
  overflow: auto;
}

.prizeBox {
  position: relative;
  background-color: #c3b8bc;
  border-radius: 10px;
  min-width: 19vw;
  width: 19vw;
  height: 19vw;
  font-size: 0.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 2vw;

  -webkit-box-shadow: 0px 0px 10px -2px rgb(107, 107, 107);
  -moz-box-shadow: 0px 0px 10px -2px rgb(107, 107, 107);
  box-shadow: 0px 0px 10px -2px rgb(107, 107, 107);
}

.giftIcon {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 4vw;
}

.input {
  border: 1px solid gray;
  color: #038e6e;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 80%;
  padding: 10px;
  direction: rtl;
}

.discountContainer {
  width: 100%;
  text-align: center;
  display: flex;
  margin-top: 5vw;
  justify-content: center;
  align-items: center;
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .startConatainer {
    font-size: 1.5em;
  }
}
