.greenBox {
  position: relative;
  background-color: #56f0aa;
  color: white;
  border-radius: 1vh;
  padding: 2vw;
  height: 17vh;
  flex: 1;
  margin: 2vw;
  display: flex;
  justify-content: space-between;
}

.prizeTag {
  position: relative;
  background-color: white;
  color: black;
  border-radius: 25px;
  padding: 0vw 10vw;
  padding-left: 3vw;
  width: fit-content;
  height: 4vh;
  text-align: left;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prizeIcon {
  width: 4vh;
  height: 4vh;
  position: absolute;
  right: 0px;
  top: 0px;
}

.taggedInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40vw;
  background-color: black;
  border-radius: 1.5vh;
  cursor: pointer;
  height: fit-content;
}

.taggedInputInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 1.5vh;
}

.taggedInputInput {
  border: 0px transparent;
  width: 100%;
  margin: 0px 3vw;
  text-align: center;
  height: 4vh;
}

.taggedInputButton {
  color: white;
  padding: 0px 5vw;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.columnFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.rowFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.FirstRowImg {
  position: absolute;
  top: 1vw;
  width: 20vh;
}

.title {
  color: black;
  font-weight: bold;
  font-size: 1em;
  white-space: nowrap;
  direction: rtl;
  z-index: 1;
}

.shareButton {
  background-color: black;
  color: white;
  border-radius: 15px;
  padding: 1vw 2vw;
  white-space: nowrap;
  font-size: 10px;
  cursor: pointer;
}

.tipText {
  color: #3c3b3b;
  font-size: 12px;
  direction: rtl;
}

.addQuestionIcon {
  height: 15vh;
}

.chargeSimIcon {
  width: 15vw;
  position: absolute;
  bottom: 2vw;
  left: 4vw;
}

.coinsIcon {
  position: absolute;
  width: 13vh;
  height: 7vh;
}

.infoIcon {
  position: absolute;
  width: 7vw;
  top: -2vw;
  left: -2vw;
  cursor: pointer;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .noTransactionText {
    font-size: 2em;
  }
}
