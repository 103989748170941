.specialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 96%;
  height: 50vw;
  margin: 2vh 0px;
  margin-left: 2vw;
  background-color: white;
  font-size: 1em;
  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 15px -2px rgb(107, 107, 107);
  -moz-box-shadow: 0px 0px 15px -2px rgb(107, 107, 107);
  box-shadow: 0px 0px 15px -2px rgb(107, 107, 107);
}

.specialContainer .topSection {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  border-radius: 3vw 3vw 0px 0px;
  width: 100%;
}

.specialContainer .topSection .priceCom {
  position: absolute;
  left: 10px;
  background-color: gold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  height: 8vw;
  padding-right: 6vw;
  width: 20vw;
  font-weight: bold;
}

.specialContainer .topSection .priceCom .price {
  font-size: 1em;
  color: black;
  font-weight: bolder;
}

.specialContainer .topSection .priceCom .icon {
  width: 5vw;
  height: 5vw;
  margin-right: 1vw;
}

.specialContainer .topSection .icon {
  width: 13vw;
  height: 13vw;
  margin-left: 10px;
}

.specialContainer .topSection .title {
  height: fit-content;
  font-weight: bold;
  font-size: 1em;
}

.specialContainer .bottomSection {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.specialContainer .bottomSection .backImage {
  width: 100%;
  height: 100%;
}

.specialContainer .bottomSection .details {
  font-size: 0.8em;
  font-weight: bolder;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
}

.specialContainer .bottomSection .details .col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.specialContainer .bottomSection .details .col .key {
  direction: rtl;
  color: var(--green);
  display: flex;
  align-items: center;
}

.specialContainer .bottomSection .details .col .value {
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40%;
  margin-top: 4vh;
  margin-left: 7vw;
  min-height: fit-content;
  background-color: white;
  font-size: 1em;
  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 15px -2px rgb(107, 107, 107);
  -moz-box-shadow: 0px 0px 15px -2px rgb(107, 107, 107);
  box-shadow: 0px 0px 15px -2px rgb(107, 107, 107);
}

.container .topSection {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 40px;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
}

.container .topSection .title {
  height: fit-content;
  font-weight: bold;
  font-size: 0.8em;
}

.container .topSection .icon {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
}

.container .bottomSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
  flex: 1;
  width: 100%;
}

.prize {
  direction: rtl;
  color: var(--green);
  font-weight: bold;
  padding-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* max-width: 120px; */
  font-size: 3vw;
}

.priceBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #6d6e70;
  border-radius: 5px;
  padding: 5px;
}

.coinImg {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.price {
  color: white;
}

.havenTickets {
  background-color: gold;
  color: black;
  padding: 2vw 3vw;
  font-size: 3vw;
  font-weight: bold;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .specialContainer,
  .container {
    font-size: 2em;
  }

  /* .icon {
    width: 20%;
  } */

  /* .topSection {
    min-height: 60%;
  } */
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    font-size: 1em;
  }
}
