.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  min-width: 20vh;
  min-height: fit-content;
  height: 25vh;
  background-color: white;
  margin-right: 10px;
  padding-right: 10px;
  font-size: 1em;
  padding: 2vw;
  margin: 2vw;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
}

.titleIcon {
  width: 20%;
  margin-left: 5%;
}

.stockContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fae25a;
  border-radius: 5px;
  width: 90%;
  padding: 5px;
  font-weight: bold;
  white-space: nowrap;
}

.discountBadgeIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 14vw;
}

.coinCardTopContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%;
  height: 100%; */
  flex: 1;
}

.price {
  direction: rtl;
  font-weight: bold;
}

.beforeDiscountPrice {
  direction: rtl;
  text-decoration: line-through;
  font-weight: bold;
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    height: 30vw;
  }
}
