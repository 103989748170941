.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #038E6E;
}

.logo {
  width: 150px;
}
