.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2vh;
}

.topSection {
  height: 60px;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 25px -2px rgb(71, 71, 71);
  -moz-box-shadow: 0px 0px 25px -2px rgb(71, 71, 71);
  box-shadow: 0px 0px 25px -2px rgb(71, 71, 71);
}

.topSectionLeft {
  width: 35%;
  height: 60px;
  /* flex: 1; */
  background-color: var(--green);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.numOfCompetitors {
  color: white;
  font-size: 0.7em;
  white-space: nowrap;
  direction: rtl;
}

.topSectionRight {
  flex: 1;
  padding-right: 5px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.topSectionIcon {
  width: 20%;
}

.topSectionRightInner {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: black;
  font-weight: bold;
  direction: rtl;
}

.timeLeft {
  color: var(--green);
  direction: rtl;
  font-size: 0.8em;
  white-space: nowrap;
}

.footer {
  direction: rtl;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logoIcon {
  width: 3vh;
  height: 3vh;
  margin-left: 5px;
}

.startConatainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  width: 70vw;
  height: 40vh;
}

.startIcon {
  width: 20vw;
  position: absolute;
  top: -7vw;
  right: -2vw;
}

.startCompetitorsText {
  direction: rtl;
  width: 20vw;
  position: absolute;
  top: -7vw;
  /* font-weight: bold; */
  font-size: 1.1em;
  /* left: -1vw; */
  white-space: nowrap;
  color: white;
}

.startTitle {
  background-color: #5f6833;
  color: white;
  padding: 15px;
  width: 100%;
  height: 13vw;
  font-weight: bold;
  font-size: 1.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
}

.startText {
  flex: 1;
  width: 100%;
  padding: 5vw;
  direction: rtl;
  font-weight: bold;
  overflow: auto;
}

.startButtonsContainer {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -6vw;
}

.startButtonText {
  color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12vw;
  width: 43vw;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 10px;
  background-color: white;

  -webkit-box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
  -moz-box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
  box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
}

.startButtonLeaderboard {
  background-color: #5f6734;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12vw;
  width: 17vw;
  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 25px -2px white;
  -moz-box-shadow: 0px 0px 25px -2px white;
  box-shadow: 0px 0px 25px -2px white;
}

.startLeaderboardIcon {
  width: 8vw;
}

.selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border: 0.6vw solid #1c3a3c;
  border-radius: 25px;
  width: 90%;
  margin: 2vw 0px;
}

.selector .option {
  padding: 1vw;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  cursor: pointer;
}

.bannerContainer {
  width: 100%;
  background-color: white;
  padding: 5%;
}

.bannerImg {
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.noData {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .topSection {
    height: 80px;
    width: 97%;
  }

  .topSectionLeft {
    height: 80px;
  }

  .topSectionIcon {
    width: 15%;
  }

  .startConatainer {
    font-size: 1.5em;
  }
}
