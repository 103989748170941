.noTransactionText {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.transactionContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4vw;
}

.transactionContainer .top {
  background-color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw;
}

.transactionContainer .top .price {
  color: white;
  padding: 0vw 1vw;
  border-radius: 5px;
  text-align: center;
  direction: rtl;
}

.transactionContainer .top .date {
  font-weight: bold;
}

.transactionContainer .bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
  padding: 3vw;
  direction: rtl;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .noTransactionText {
    font-size: 2em;
  }
}
