.startConatainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #aaaaaa;
  border-radius: 20px;
  width: 90vw;
  padding: 4vw;
}

.avatarConatainer {
  background-color: #aaaaaa;
  position: absolute;
  right: 0px;
  top: -6vh;
  padding: 1vh;
  width: 15vh;
  height: 15vh;
  border-radius: 10vh;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 10vh;
  background-color: white;
}

.name {
  color: #383838;
  direction: rtl;
  font-weight: bold;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 2vh 1vh;
  margin-top: 3vh;
}

.field {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.field .icon {
  width: 4vh;
  height: 4vh;
  position: absolute;
}

.field .value {
  background-color: white;
  color: black;
  border-radius: 5vh;
  width: 22vh;
  font-size: 3vh;
  height: 5vh;
  display: flex;
  justify-content: center;
}

.field .title {
  background-color: #585a57;
  color: white;
  position: absolute;
  bottom: -2vh;
  border-radius: 5vh;
  padding: 0vh 2vh;
  font-size: 2vh;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .startConatainer {
    font-size: 1.5em;
  }
}
