.container {
  background-color: #59b1d9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px 30px 15px 15px;
  height: 75%;
  min-height: fit-content;
  width: 90%;
  padding-bottom: 15px;
}

.wallet {
  background: transparent url("../../resources/img/wallet_back.png") no-repeat;
  background-size: 100%;
  width: 100%;
  min-height: 48vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: rtl;
  padding-right: 20%;
}

.withdrawText {
  color: #15bb88;
  margin-right: 5px;
  font-size: 0.8em;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.fieldContainer {
  width: 90%;
  height: 6vh;
  min-height: fit-content;
  background-color: white;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1vh 4vh;
  margin-top: 5vw;
  font-size: 2vh;
}

.rowButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.fieldImg {
  min-width: 4vh;
  width: 4vh;
  height: 4vh;
}

.fieldInput {
  direction: rtl;
  width: 100%;
  background-color: transparent;
  border-color: transparent;
}

.fieldTitle {
  color: #005c47;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 35%;
  margin-left: 1vh;
}

.withdrawTipText {
  color: white;
  font-weight: bold;
  direction: rtl;
  text-align: center;
  width: 90%;
  margin-top: 5vw;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .wallet {
    font-size: 2em;
  }

  .withdrawTipText {
    width: 90%;
    margin-top: 3vw;
  }
}
