
.container {
  background-color: #aaaaaa;
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}

.icon {
  width: 6vw;
  margin-right: 5px;
}
