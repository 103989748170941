.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  background-color: var(--green);
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--green);
  overflow: auto;
}

.loadingModal {
  z-index: 10000;
  width: 100%;
  height: 100%;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  position: relative;
  background-color: var(--green);
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.backIcon {
  position: absolute;
  left: 5px;
  height: 70%;
}

.title {
  direction: rtl;
  color: white;
  font-size: 1.5em;
}

.rightContainer {
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 90%;
}

.coinContainer {
  height: 3.7vh;
  font-size: 3vh;
  min-width: 20vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fae359;
  border-radius: 5px;
  color: var(--green);
  margin-right: 4vw;
  padding-left: 2vw;
}

.coinIcon {
  height: 100%;
  margin-left: 2vw;
  margin-right: -2vw;
}

.footer {
  background-color: var(--green);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .title {
    font-size: 2.5em;
  }
}
