.noTransactionText {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.lawContainer {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10vw;
  margin-bottom: 5vw;
  width: 90%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  direction: rtl;
}

.text {
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  color: gray;
  font-size: 18px;
  direction: rtl;
}

.title {
  color: #009c74;

  border-bottom: 1px solid gray;
  font-size: 20px;
  font-weight: bold;
  padding: 3vw 2vw;
}

.ruleIcon {
  width: 11vw;
  height: 11vw;
  position: absolute;
  top: -5vw;
  right: 0;
  padding: 5px;
  background-color: white;
  border-radius: 50px;
}

.acceptButton {
  background-color: #009c74;
  color: white;
  border-radius: 25px;
  width: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .noTransactionText {
    font-size: 2em;
  }
}
