.timerContainer {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -7vh;
  z-index: 2;
}

.timerImg {
  width: 20vh;
  height: 20vh;
}

.timerNumber {
  position: absolute;
  font-weight: bold;
  font-size: 10vw;
}

.questionNumber {
  color: black;
  position: absolute;
  top: 0px;
  font-size: 1.3em;
  font-weight: bold;
  background-color: white;
  padding: 2vw;
  width: 32vw;
  height: 6vh;
  border-radius: 0px 0px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeLineContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5vw;
}

.timeLineIconContainer {
  position: relative;
}

.timeLineIcon {
  width: 5vw;
}

.topContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: fit-content;
  width: 100%;
  background-color: white;
}

.helpContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-top: 8vh;
  padding-bottom: 1vh;
  border-bottom: 1.6vw solid #e0cb08;
  background-color: white;
  min-height: fit-content;
}

.helpButton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.helpButton .loadingContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.5);
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.helpButtonIcon {
  width: 12vh;
  height: 12vh;
}

.helpButtonPrice {
  color: white;
  background-color: gray;
  border-radius: 5px;
  padding: 1vw;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -6vw;
}

.helpButtonCoinIcon {
  width: 4vw;
  height: 4vw;
  margin-left: 2vw;
}

.helpTextResult {
  position: absolute;
  z-index: 1;
  display: flex;
  background-color: white;
  right: 0;
  border: 3px dashed;
  width: 90%;
  height: 20vw;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  direction: rtl;

  -webkit-box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
  -moz-box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
  box-shadow: 0px 0px 25px -2px rgb(34, 34, 34);
}

.closeIcon {
  width: 6vw;
  position: absolute;
  left: 2vw;
  top: 3vw;
}

.bottomContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.gameIcon {
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
}

.questionContainer {
  direction: rtl;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 18vh;
  height: inherit;
  width: 90%;
  font-size: 1.1em;
  border-radius: 0px 0px 10px 10px;
  background-color: #4b4d4a;
  color: white;
  padding: 1vw;
  white-space: pre-wrap;
}

.answersContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.answersRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.answerButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 8vh;
  margin: 2vw;
  border: 1.4vw solid #e0cb08;
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  white-space: pre;
  font-size: 0.8em;
}

.coinContainer {
  position: absolute;
  right: 0px;
  display: flex;
  height: 3.4vh;
  min-width: 14vh;
  width: fit-content;
}

.coinText {
  direction: rtl;
  display: flex;
  align-items: center;
  flex: 1;
  background-color: white;
  border-radius: 5px;
  color: var(--green);
  padding-left: 2vw;
  padding-right: 4vh;
  min-width: fit-content;
}

.coinIcon {
  height: 100%;
  margin-left: -14px;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .gameIcon {
    margin-top: 4vw;
  }

  .questionContainer {
    width: 90%;
    font-size: 1.3em;
  }

  .answerButton {
    height: 10vw;
    font-size: 1.6em;
  }
}
