.noTransactionText {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  direction: rtl;
  text-align: center;
}

.ranksContainer {
  width: 95%;
  overflow: auto;
  padding-bottom: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.userRankContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2vw;
}

.userLevelRight {
  display: flex;
  flex-direction: row;
}

.userRankProfilePicture {
  width: 15vw;
  height: 15vw;
}

.userLevelContainer {
  position: relative;
  background-color: #fcde7c;
  color: #044259;
  font-weight: bold;
  font-size: 1.4em;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  margin-right: 2vw;
}

.userLevelTop {
  position: absolute;
  top: -6px;
  background-color: #333333;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: lighter;
  font-size: 0.6em;
  width: 100%;
}

.typeRow {
  position: relative;
  background-color: white;
  border-radius: 10px;
  width: 70%;
  height: 10vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4vw;
  padding: 2vw;
  cursor: pointer;
}

.typeRow .icon {
  position: absolute;
  left: -4vh;
  width: 10vh;
}

.typeRow .backImg {
  width: 8vh;
}

.typeRow .text {
  color: black;
  font-weight: bold;
  margin-right: 6vw;
}

.rowContainer {
  position: relative;
  background-color: white;
  border-radius: 10vh;
  min-height: 25vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vh;
}

.rowLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 11vw;
}

.rowPos {
  color: var(--green);
  font-weight: bold;
  font-size: 1.5em;
  height: 100%;
  padding: 0px 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowAvatar {
  position: absolute;
  left: 1vw;
  width: 23vw;
  height: 23vw;
  padding: 1vw;
  background-color: #75eada;
  border-radius: 50%;
}

.rowLeftTop {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 14vw;
}

.rowLeftBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #1e2f39;
  border-radius: 10px;
  color: white;
  font-size: 0.8em;
  height: 15vw;
  padding-left: 14vw;
}

.rowName {
  color: var(--green);
  font-weight: bold;
  direction: rtl;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 29vw;
  white-space: nowrap;
}

.rowScore {
  background-color: #5581f2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0vw 2vw;
  color: white;
  font-size: 1.1em;
}

.rowScoreIcon {
  width: 4vw;
  margin-left: 2vw;
}

.rowLeftBottomTitle {
  color: gold;
  direction: rtl;
}

.rowLeftBottomNumber {
  direction: rtl;
}

.columnFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scoreBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vw 2vw;
  margin: 0px 1vw;
  border-radius: 1vw;
}

.scoreBox .icon {
  width: 3vw;
  margin-left: 2vw;
}

.rowFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3vw;
}

.tagLine {
  position: relative;
  width: 100%;
  height: 4vw;
  margin-top: 5vw;
  border-top: 0.5vw solid white;
}

.tagLineText {
  position: absolute;
  top: -4vw;
  left: 40%;
  color: white;
  padding: 0px 2vw;
  background-color: var(--green);
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}
