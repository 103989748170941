.row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  min-height: 29vh;
  height: 29vh;
  overflow: auto;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  min-height: fit-content;
  padding: 2vw;
  background-color: #333333;
  border-radius: 5px;
  margin: 5px;
  margin-top: 10px;
}

.sectionHeaderTitle {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.sectionHeaderIcon {
  height: 10vw;
  width: 10vw;
}

.bannerImg {
  width: 95%;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 2vw;
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .sectionHeaderTitle {
    font-size: 2em;
  }
}
