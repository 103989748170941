.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 95%;
  min-height: fit-content;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: white;
  padding: 15px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.leftContainer {
  width: 100%;
  margin-right: 20px;
}

.title {
  direction: rtl;
  font-weight: bold;
}

.titleCenter {
  text-align: center;
  direction: rtl;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.text {
  direction: rtl;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon {
  margin: 5vw;
  margin-left: 1vw;
  width: 15vw;
}

.toggleSizeButton {
  position: absolute;
  background-color: #29505f;
  border-radius: 10px;
  bottom: -18px;
  left: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 7px;
  direction: rtl;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    font-size: 2em;
  }
}
