.startConatainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  width: 70vw;
  padding: 4vw;
}

.startIcon {
  width: 8vh;
  height: 8vh;
}

.startButtonText {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 15px;
  background-color: white;
  border: 2px solid var(--green);
  cursor: pointer;
  text-decoration: none;
  margin-top: 3vh;
}

.resTitle {
  width: 90%;
  font-weight: bold;
  margin-top: 3vw;
  text-align: center;
  direction: rtl;
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .startConatainer {
    font-size: 1.5em;
  }
}
