.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  width: 95%;
  height: 5vh;
  background-color: #284E5B;
  padding: 5px;
  margin-top: 5px;
}

.optionText {
  flex: 1;
  text-align: center;
  cursor: pointer;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    font-size: 1.5em;
  }
}
