.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  height: 250px;
  width: 300px;
  flex-direction: column;
  padding: 20px;
}

.title {
  color: #038E6E;
  font-size: 20px;
  direction: rtl;
  white-space: nowrap;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
}

.input {
  direction: rtl;
  border: 1px solid gray;
  color: #038E6E;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  direction: rtl;
}

.tipText {
  margin-top: 10px;
  color: red;
  text-align: right;
  direction: rtl;
}
